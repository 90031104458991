// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-bacalhau-bras-js": () => import("./../../../src/pages/bacalhau-bras.js" /* webpackChunkName: "component---src-pages-bacalhau-bras-js" */),
  "component---src-pages-bacalhau-legumes-js": () => import("./../../../src/pages/bacalhau-legumes.js" /* webpackChunkName: "component---src-pages-bacalhau-legumes-js" */),
  "component---src-pages-bacalhau-salada-js": () => import("./../../../src/pages/bacalhau-salada.js" /* webpackChunkName: "component---src-pages-bacalhau-salada-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-page-2-js": () => import("./../../../src/pages/page-2.js" /* webpackChunkName: "component---src-pages-page-2-js" */),
  "component---src-pages-papelote-bacalhau-js": () => import("./../../../src/pages/papelote-bacalhau.js" /* webpackChunkName: "component---src-pages-papelote-bacalhau-js" */),
  "component---src-pages-using-typescript-tsx": () => import("./../../../src/pages/using-typescript.tsx" /* webpackChunkName: "component---src-pages-using-typescript-tsx" */)
}

